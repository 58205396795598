import * as React from 'react'

const NetlifyForm = () => {
    return (
        <form name="Demo Thelgo" method="post" data-netlify="true" netlify-honeypot="bot-field" action='/demo/richiesta-inviata'>
            <input type="hidden" name="form-name" value="Demo Thelgo" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <label htmlFor="nome">
                        <span className="text-sm">Nome</span>
                        <input required id="nome" type="text" name="nome" className="block w-full mt-1" />
                    </label>
                </div>
                <div>
                    <label htmlFor="cognome">
                        <span className="text-sm">Cognome</span>
                        <input required id="cognome" type="text" name="cognome" className="block w-full mt-1" />
                    </label>
                </div>
                <div>
                    <label htmlFor="email">
                        <span className="text-sm">Indirizzo email</span>
                        <input required id="email" type="email" name="email" className="block w-full mt-1" />
                    </label>
                </div>
                <div>
                    <label htmlFor="telefono">
                        <span className="text-sm">Numero di telefono</span>
                        <input required id="telefono" type="text" name="telefono" className="block w-full mt-1" />
                    </label>
                </div>
            </div>
            <div className="mt-8">
                <label htmlFor="ragioneSociale" className="block mb-4">
                    <span className="text-sm">Ragione Sociale</span>
                    <input required id="ragioneSociale" type="text" name="ragioneSociale" className="block w-full mt-1" />
                </label>
            </div>
            <div>

                <label htmlFor="note" className="block mb-4">
                    <span className="text-sm">Note</span>
                    <textarea id="note" name="note" className="block w-full mt-1"></textarea>
                </label>

            </div>
            <div>
                <label htmlFor="consenso" className="block mb-4">
                    <input type="checkbox" required name="consenso" /> <span className="text-sm">Autorizzo il trattamento dati personali come previsto dalla <a href="https://www.iubenda.com/privacy-policy/8074034" target="_blank" className="text-red-500 hover:text-red-600" rel="noopener noreferrer">Privacy Policy</a></span>
                </label>
            </div>
            <button className="bg-green-400 text-white uppercase text-sm px-4 py-2 rounded-md mt-4" type="submit">
                Invia la tua richiesta
            </button>
        </form>
    )
}

export default NetlifyForm