import * as React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import NetlifyForm from '../../components/netlify-form'

const Demo = () => {
    return (
        <Layout>
            <SEO title="Prova gratuitamente Thelgo" />
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-center justify-between gap-8">
                        <div className="w-full lg:w-5/12 flex items-center">
                            <StaticImage src="../images/single01.png" alt="Demo Gratuita Thelgo" placeholder="blurred" className="mx-auto w-1/3 lg:w-full" />
                        </div>
                        <div className="flex-1">
                            <h1 className="text-4xl lg:text-5xl text-center">Accedi alla demo gratuita di Thelgo</h1>
                            <span className="block text-lg lg:text-xl text-gray-500 text-center mt-4">Compila il modulo di richiesta o inviaci una mail direttamente a info@cascinanet.it</span>
                            <div className="mt-12">
                                <NetlifyForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Demo